<template>
<div>
    <b-card-actions action-collapse title="Client Form">  
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col cols="12" class="mb-2">
                        <div class="border rounded p-2">
                            <h4 class="mb-1">
                                Avatar
                            </h4>
                            <b-media
                                no-body
                                vertical-align="center"
                                class="flex-column flex-md-row"
                            >
                                <b-media-aside>
                                    <b-img
                                        id ="profile"
                                        ref="refPreviewEl"
                                        :src="input.avatar_pathurl"
                                        height="110"
                                        width="170"
                                        class="rounded mr-2 mb-1 mb-md-0"
                                    />
                                </b-media-aside>
                                <b-media-body>
                                    <small class="text-muted">(Recommend Size : 200x200)</small>
                                    <b-card-text class="my-50">
                                        <b-link id="blog-image-text">
                                            {{ input.userFile ? input.userFile.name : 'banner.jpg' }}
                                        </b-link>
                                    </b-card-text>
                                    <div class="d-inline-block">
                                        <b-form-file
                                            ref="refInputEl"
                                            v-model="input.userFile"
                                            accept=".jpg, .png, .gif"
                                            placeholder="Choose file"
                                            @input="inputImageRenderer"
                                        />
                                    </div>
                                </b-media-body>
                            </b-media>
                        </div>
                    </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <label>Email</label>
                            <draggable v-model="input.emails" tag="div"  class="py-1" handle=".handle" v-if="input.emails.length > 0">
                                <div class="d-flex justify-content-between mt-1" v-for="(em,index) in input.emails" :key="'email-'+index">
                                    <div>
                                        <feather-icon   class="handle size-25  mr-3" style="font-size:20px;" icon="MenuIcon" />
                                    </div>
                                    <div class="w-100 text-left">{{  em  }}</div>
                                    <div> 
                                        <b-badge
                                            variant="danger"
                                            @click="removeEmail(index)"
                                        >
                                            <feather-icon
                                                icon="XIcon"
                                            />
                                        </b-badge>
                                    </div>
                                </div>
                            </draggable>
                            <validation-provider
                                #default="{ errors }"
                                rules="min:0"
                                name="emails"
                            >
                                <b-input-group>
                                    <b-form-input v-model="email" placeholder="Email" />
                                    <b-input-group-append >
                                        <b-button variant="outline-primary" @click="addEmail">
                                            Add
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <label>Phone</label>
                            <draggable v-model="input.phones" tag="div"  class="py-1" handle=".handle" v-if="input.phones.length > 0">
                                <div class="d-flex justify-content-between mt-1" v-for="(ph,index) in input.phones" :key="'ph-'+index">
                                    <div>
                                        <feather-icon   class="handle size-25  mr-3" style="font-size:20px;" icon="MenuIcon" />
                                    </div>
                                    <div class="text-left w-100">{{  ph.phone_no  }}</div>
                                    <div class="text-left w-100">{{  ph.phone_name  }}</div>
                                    <div> 
                                        <b-badge
                                            variant="danger"
                                            @click="removePhone(index)"
                                        >
                                            <feather-icon
                                                icon="XIcon"
                                            />
                                        </b-badge>
                                    </div>
                                </div>
                            </draggable>
                            <validation-provider
                                #default="{ errors }"
                                rules="min:0"
                                name="phone"
                            >
                                <b-input-group>
                                    <b-form-input v-model="phone_no" placeholder="Phone No" />
                                    <b-form-input v-model="phone_name" placeholder="Name" />
                                    <b-input-group-append >
                                        <b-button variant="outline-primary" @click="addPhone">
                                            Add
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <label>First name</label>
                             <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="firstname"
                                >
                                <b-form-input
                                    v-model="input.firstname"
                                    placeholder="First Name"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <label>Last Name</label>
                            <validation-provider
                                 #default="{ errors }"
                                rules="min:0"
                                name="lastname"
                                >
                               <b-form-input
                                    v-model="input.lastname"
                                    placeholder="Last Name"
                                />
                                  <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                                
                               
                        </b-form-group>
                    </b-col>
                     <b-col md="12">
                        <b-form-group>
                            <label>Comment</label>
                             <validation-provider
                                 #default="{ errors }"
                                rules="min:0"
                                name="comment"
                                >
                               <b-form-textarea
                                    v-model="input.comment"
                                    placeholder="Comment"
                                />
                                  <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                               
                             
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <label>Address</label>
                            <validation-provider
                                 #default="{ errors }"
                                rules="min:0"
                                name="address"
                                >
                              <b-form-textarea
                                    v-model="input.address"
                                    placeholder="Address"
                                />
                                  <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                               
                                
                                
                        </b-form-group>
                    </b-col>
                     <b-col md="3">
                        <b-form-group>
                            <label>Country</label>
                             <validation-provider
                                 #default="{ errors }"
                                rules="min:0"
                                name="country"
                                >
                              <b-form-input
                                    v-model="input.country"
                                    placeholder="Country"
                                />
                                  <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                               
                               
                               
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group>
                            <label>Group</label>
                           <validation-provider
                                 #default="{ errors }"
                                rules="required"
                                name="type_id"
                                >
                              <v-select
                                            v-model="input.type_id"
                                            :reduce="(option) => option.id"
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            label="name"
                                            :options="groups"
                                    />
                                  <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                                
                               
                        </b-form-group>
                    </b-col>
                     <b-col md="3">
                        <b-form-group>
                            <label>Active</label>
                            <validation-provider
                                 #default="{ errors }"
                                rules="required"
                                name="is_active"
                                >
                              <b-form-checkbox
                                checked="true"
                                class="custom-control-primary"
                                v-model="input.is_active"
                                switch
                            />
                                  <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                           
                        </b-form-group>  
                        
                    </b-col>
                  
                    <b-col cols="12">
                        <b-button
                            variant="primary"
                            type="submit"
                            @click.prevent="validationForm"
                        >
                            Save
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card-actions>
    <property-lists-owner  v-if="id != undefined"  />
    <enquiry-list-client  v-if="id != undefined" />
</div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BForm, BBadge, BRow, BCol, BButton,BFormTextarea,BFormRating,BFormCheckbox,BFormCheckboxGroup,BFormTags, BContainer
} from 'bootstrap-vue'
import {
  BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BImg, BFormFile, BLink
} from 'bootstrap-vue'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import BCardActions from '../../../../@core/components/b-card-actions/BCardActions.vue'
import axios from '@axios'
import PropertyListsOwner from '@/views/properties/property-list/PropertyListsOwner.vue'
import EnquiryListClient from '@/views/enquiry/enquiry-list/EnquiryListClient.vue'
import draggable from 'vuedraggable'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from "@/store";
import clientStoreModule from '../clientStoreModule'

export default {
    components: {
        BCardActions,
        BBadge,
        BInputGroup, BInputGroupAppend,
        VueAutosuggest,
        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormTextarea,
        BFormRating,
        vSelect,
        BFormCheckbox,
        BFormCheckboxGroup,
        BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BImg, BFormFile, BLink,
        BFormTags,
        PropertyListsOwner,
        EnquiryListClient,
        draggable,
        BContainer
    },

    directives: {
        Ripple,
    },

    data(){
        return {
            id:router.currentRoute.params.id,
            email:'',
            phone_no:'',
            phone_name: '',
            input : {
                email:null,
                emails:[],
                phone_no:null,
                phone_name: null,
                phones:[],
                password:null,
                firstname:null,
                lastname:null,
                comment:null,
                address:null,
                country:null,
                is_active:true,
                userFile:null,
                type_id:null,
                avatar_pathurl:null,
                avatar_url:null,
                role:'user'
            },
            passwordCon: null,
            userEdit: {},
            emails:[],
            phones:[],
            groups:[],
        }
    },

    setup() {
        const CLIENT_STORE_MODULE_NAME = "app-client";
        if (!store.hasModule(CLIENT_STORE_MODULE_NAME)) store.registerModule(CLIENT_STORE_MODULE_NAME, clientStoreModule);
        onUnmounted(() => {
            if (store.hasModule(CLIENT_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_STORE_MODULE_NAME);
        });

        const refInputEl = ref(null)
        const refPreviewEl = ref(null)
        const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

        return {
            refInputEl,
            refPreviewEl,
            inputImageRenderer,
        }
    },

    watch: {
        '$route.params.id'(newId, oldId) {
            this.id = newId
            this.getData();
        },
    },

    created() {
        this.getGroups();
        if(this.id != undefined){
            setTimeout(() => {
                this.getData();
            }, 500);
        }else{
            this.input.type_id = 3;
        }
    },
  
    methods: {
        async removeEmail(index){
        this.input.emails.splice(index,1)
    },

    async addEmail(){
        let d = {
            email:this.email
        }
        var data = JSON.stringify(d);
        const res = await axios.post('users/v1/checkemail',data);
        
        if(res.data.success === true){
            this.input.emails.push(this.email);
        }else{
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: res.data.message,
                },
            })
        }
        
        this.email='';
    },

    async removePhone(index){
        this.input.phones.splice(index,1)
    },

    async addPhone(){
        let d = {
            phone:this.phone_no,
            phone_name: this.phone_name
        }
        var data = JSON.stringify(d);
        const res = await axios.post('users/v1/checkphone',data);
      
        if(res.data.success === true){
            const data = {
                phone_no: this.phone_no,
                phone_name: this.phone_name
            }

            this.input.phones.push(data);
            this.phone_no = ''
            this.phone_name = ''
        }else{
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: res.data.message,
                },
            })

        }
        
        this.phones='';
    },

    async getGroups(){
        axios.get('users/v1/groups/user')
            .then(res => {
                this.groups = res.data.data; 
            })

    },

    async getData(){
        let user = []
        await store
            .dispatch('app-client/fetchUsers', {
                id: this.id
            })
            .then(response => {
                user = response.data.data[0]
            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching User",
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: `${error?.response?.data?.message??'Something went wrong'}`
                    },
                })
            })
        
        if(!user.id){
            this.$router.push('/clients/lists', () => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `ERror`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: `Data not Found.`,
                },
              })
          });
        }else{
            let usr = user;
            this.input.type_id = usr.type_id;
            this.input.email =  usr.email;
            this.input.phone =  usr.phone;
            this.input.firstname =  usr.firstname;
            this.input.lastname =  usr.lastname;
            this.input.comment =  usr.comment;
            this.input.address =  usr.address;
            this.input.country =  usr.country;
            this.input.emails = usr.emails.map((item) => { return item.email});
            this.input.phones = usr.phones
            this.input.is_active =  usr.is_active==1?true:false;
            this.input.password =  null;
            this.input.avatar_pathurl = usr.avatar_pathurl;
            this.input.avatar_url = usr.avatar_url;
            this.emails = usr.emails;
            this.phones = usr.phones;
        }
    },

    makeToast(variant = null,title,message) {
        this.$bvToast.toast(message, {
            title: title,
            variant,
            solid: true,
        })
    },

    validationForm() {
        const userStorage = JSON.parse(localStorage.getItem('userData'))
        this.$refs.simpleRules.validate().then(success => {
            if (success) {
                if(this.input.phones){
                    this.input.phone = this.input.phones[0];
                    // this.input.phone_remove = this.phones?.filter((itm) => { 
                    //     let index = this.input.emails.findIndex(item => item === itm.phone );
                        
                    //     if(index < 0){
                    //         return itm.id
                    //     }
                    // });

                    // this.input.phone_add = this.input?.phones?.filter((itm) => { 
                    //     let index = this.phones.findIndex(item => item.phone === itm );
                        
                    //     if(index < 0){
                    //         return itm
                    //     }
                    // });
                }else{
                    this.input.phone = '';
                }

                if(this.input.emails){
                    this.input.email = this.input.emails[0];
                     
                    this.input.email_remove = this.emails.filter((itm) => { 
                        let index = this.input.emails.findIndex(item => item === itm.email );

                        if(index < 0){
                            return itm.id

                        }
                    });

                    this.input.email_add = this.input.emails.filter((itm) => { 
                        let index = this.emails.findIndex(item => item.email === itm );
                        if(index < 0){
                            return itm
                        }
                    });
                }

                let data = null
                  
                if(this.id > 0){
                    this.input.updated_by = userStorage.id

                    const formData = new FormData();
                    data = this.input
                    formData.append('imageFile', this.refInputEl.files[0])
                    formData.append('data', JSON.stringify(data))
                    console.log('checcck data ', data)
                    store.dispatch("app-client/updateUser", {
                        id: this.id,
                        data: formData
                    }).then((res) => {
                        this.$router.push('/clients/list', () => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                title: `Done`,
                                icon: 'CoffeeIcon',
                                variant: 'success',
                                text: `You have successfully updated.`,
                                },
                            })
                        });
                    }).catch((error) => {
                        this.makeToast('danger', 'Error 403', `${error?.response?.data?.message??'Something went wrong'}`)
                    })

                    // axios.put('users/v1/update/'+this.id,data)
                    // .then(res => { 
                      
                    //     if(!res.data.success){
                    //     this.makeToast('danger','Error 403',res.data.message);
                        
                        
                    //     }else{
                    //         this.$toast({
                    //             component: ToastificationContent,
                    //             position: 'top-right',
                    //             props: {
                    //             title: `Done`,
                    //             icon: 'CoffeeIcon',
                    //             variant: 'success',
                    //             text: `You have successfully created.`,
                    //             },
                    //         })
                    //     }

                    // }).catch(error => {
                    //     this.makeToast('danger','Error 403',error);
                    // })
                } else {
                    this.input.created_by = userStorage.id

                    const formData = new FormData();
                    data = this.input
                    formData.append('imageFile', this.refInputEl.files[0])
                    formData.append('data', JSON.stringify(data))

                    store.dispatch("app-client/addUser", {
                        data: formData
                    }).then((res) => {
                        this.$router.push('/clients/list', () => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                title: `Done`,
                                icon: 'CoffeeIcon',
                                variant: 'success',
                                text: `You have successfully created.`,
                                },
                            })
                        });
                    }).catch((error) => {
                        this.makeToast('danger', 'Error 403', `${error?.response?.data?.message??'Something went wrong'}`)
                    })
                }
              }
          });
      }

  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>